import appConfig from '@/../config/config.json';

import Standard from "@/components/card/elements/Standard.vue";
import TwitterBox from "@/components/card/elements/TwitterBox.vue";
import QuestionAnswerBox from "@/components/card/elements/QuestionAnswerBox";
import NewsContainer from "@/components/card/elements/NewsContainer.vue";
import ArticleContainer from "@/components/card/elements/ArticleContainer.vue";
import KnowledgePanel from "@/components/card/elements/KnowledgePanel.vue";
import PositionedKnowledgePanel from "@/components/card/elements/PositionedKnowledgePanel.vue";
import VideoContainer from "@/components/card/elements/VideoContainer.vue";
import Sitelink from "@/components/card/elements/Sitelink.vue";
import Imagebox from "@/components/card/elements/Imagebox.vue";
import LatestFrom from "@/components/card/elements/LatestFrom.vue";
import Snippet from "@/components/card/elements/Snippet.vue";
import Faq from "@/components/card/elements/Faq.vue";
import YoutubeCarousel from "@/components/card/elements/YoutubeCarousel.vue";
import ProfilePanel from "@/components/card/elements/ProfilePanel.vue";
import Generic from "@/components/card/elements/Generic.vue";
import MiniSiteLinksResult from "@/components/card/elements/MiniSiteLinksResult";
import FeaturedResultsPanel from "@/components/card/elements/FeaturedResultsPanel";
import ShortVideosContainer from "@/components/card/elements/ShortVideosContainer";
import LatestPostsBox from "@/components/card/elements/LatestPostsBox";
import PerspectivesBox from "@/components/card/elements/PerspectivesBox";
import WhatPeopleAreSayingBox from "@/components/card/elements/WhatPeopleAreSayingBox";


export const Result = Object.freeze({
  TYPE_LATEST_FROM: "latestfrombox",
  LEVEL_SECOND: "Second Level Result",
});

export const ToolsSelection = Object.freeze({
  DOWNLOAD_CSV: "DownloadCSV",
});

// TODO dynamically import everything in this folder https://stackoverflow.com/questions/54344164/how-to-import-all-vue-components-from-a-folder
export const ElementType = Object.freeze({
  Standard,
  TwitterBox,
  Generic,
  QuestionAnswerBox,
  NewsContainer,
  ArticleContainer,
  KnowledgePanel,
  PositionedKnowledgePanel,
  VideoContainer,
  Sitelink,
  Imagebox,
  LatestFrom,
  Snippet,
  Faq,
  YoutubeCarousel,
  ProfilePanel,
  MiniSiteLinksResult,
  FeaturedResultsPanel,
  ShortVideosContainer,
  LatestPostsBox,
  PerspectivesBox,
  WhatPeopleAreSayingBox,
});

export const HighlightScopes = Object.freeze({
  SINGLE: "single",
  URL: "url",
  DOMAIN: "domain",
});

export const Views = Object.freeze({
  PARSING_TESTER: "parsing-tester",
});
export const ZoomSelectionOptions = Object.freeze({
  REGULAR_VIEW: "regular-view",
  MEDIUM_VIEW: "medium-view",
  SMALL_VIEW: "small-view",
});

export const IMPACT_QUERY_DATE_FORMAT = "dLLLyyyy";
export const LONG_DATE_FORMAT = "MMMM d, yyyy";
export const MEDIUM_DATE_FORMAT = "MMM d, yyyy";
export const SHORT_DATE_FORMAT = "MMM d";
export const API_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'+0000'"; // "2023-09-18T00:11:02+0000";

export const TIMELINE_NO_DATA_MSG = "No results available for this period";
export const TIMELINE_NO_DATA_FOR_CLIENT_MSG = "No data available for this client";

export const IRESULTS_AUTO_SAVE_INTERVAL = 30000;

export const DEFAULT_NEWS_IMAGE = "/images/default_news_image.png";

export const sidebarItems = Object.freeze([
  {
    label: "Search",
    icon: "search",
    disabled: true,
    suOnly: true,
  },
  {
    label: "Timeline",
    icon: "timeline",
    route: "/timeline",
  },
  {
    label: "Knowledge Panels",
    icon: "knowledge-panels",
    route: "/timelineKP",
  },
  {
    label: "iResults",
    icon: "i-results",
    route: "/iresults",
    suOnly: true,
  },
  {
    separator: true,
    suOnly: true,
  },
  {
    label: "Program Term Summary",
    icon: "program-term-summary",
    route: "/keywordSummary",
    suOnly: true,
  },
  {
    label: "Client Management",
    icon: "client-management",
    route: "clients",
    legacy: true,
    clientOwnerOnly: true,
  },
  {
    label: "Keyword Management",
    icon: "keyword-management",
    route: "keywords",
    legacy: true,
  },
  {
    separator: true,
    suOnly: true,
  },
  {
    label: "Notification Reports",
    icon: "icons notifications",
    route: "/notificationReports",
    suOnly: true,
  },
  {
    label: "Notifications Management",
    icon: "icons notifications-management",
    route: "/notificationsManagement",
    suOnly: true,
  },
  {
    separator: true,
    suOnly: true,
  },
  {
    label: "AIQ",
    icon: "ai-queries",
    route: appConfig.aisaas_url,
    newWindow: true,
    suOnly: true,
  },
  {
    label: "AIQ",
    icon: "ai-queries",
    route: "https://www.fiveblocks.com/aiq-tracking-analysis-of-ai-powered-search/",
    newWindow: true,
    nonSuOnly: true,
  },
  {
    label: "Legacy Timeline",
    icon: "legacy-timeline",
    route: "results",
    legacy: true,
    suOnly: true,
  },
  {
    label: "Data Reports",
    icon: "data-reports",
    disabled: true,
    suOnly: true,
  },
  {
    label: "Settings",
    icon: "settings",
    disabled: true,
    suOnly: true,
  },
]);
